import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  OrderedList,
  Button,
  HStack,
  Image,
  Container,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavBar } from "./components/NavBar";
import { CookiesProvider, useCookies } from "react-cookie";
export const Recommendations = ({}) => {
  const [searchloading, setSearchLoading] = useState(false);
  const [recloading, setRecLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const handleSelected = (item) => {
    setRecLoading(true);
    console.log("runningrec");
    // Simulate form submission delay
    setTimeout(() => {
      axios
        .get(`https://api.jikan.moe/v4/anime/${item["mal_id"]}/recommendations`)
        .then(function (response) {
          // handle success
          setRecommendations(response.data.data);
          console.log(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          setRecLoading(false);
        });
    }, 2000);
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchLoading(true);
      setRecommendations([]);
      setRecLoading(false);
      console.log("running");
      const delayDebounceFn = setTimeout(() => {
        setSearchLoading(false);
        console.log(searchTerm);
        axios
          .get(`https://api.jikan.moe/v4/anime?q=${searchTerm}&page=1&limit=5`)
          .then(function (response) {
            // handle success
            console.log(response);
            setResults(response.data.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);
  return (
    <CookiesProvider>
      <NavBar title={"Find My Next Anime"} />
      <Container minW={"50vw"} centerContent={false}>
        <Box textAlign="left" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <VStack spacing={8}>
              <InputGroup>
                <InputLeftAddon>Search for an anime: </InputLeftAddon>
                <Input
                  type="text"
                  name="username"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              {searchloading && (
                <div style={{ marginTop: 5, fontWeight: "bold" }}>
                  Searching...
                </div>
              )}
              {searchloading == false && results.length > 0 && (
                <VStack align={"flex-start"}>
                  {results.map((data, key) => (
                    <HStack key={key} minW={"50vw"}>
                      <VStack>
                        <Image
                          boxSize="75px"
                          objectFit="cover"
                          src={data["images"]["jpg"]["large_image_url"]}
                        />
                      </VStack>
                      <VStack>
                        <Box
                          as="button"
                          display="flex"
                          mt="2"
                          alignItems="center"
                          onClick={() => {
                            setResults([data]);
                            handleSelected(data);
                          }}
                        >
                          {data?.title_english ??
                            data["title"] ??
                            data?.titles[0]}
                        </Box>
                      </VStack>
                    </HStack>
                  ))}
                </VStack>
              )}
              {searchloading == false && recloading && (
                <div style={{ marginTop: 5, fontWeight: "bold" }}>
                  Looking up recommendations...
                </div>
              )}
              {recloading == false && recommendations.length > 0 && (
                <VStack align={"flex-start"}>
                  <div style={{ marginTop: 5, fontWeight: "bold" }}>
                    Recommendations:
                  </div>
                  {recommendations.slice(0, 5).map((recdata, key) => (
                    <HStack key={key} minW={"50vw"}>
                      <VStack>
                        <Image
                          boxSize="75px"
                          objectFit="cover"
                          src={
                            recdata["entry"]["images"]["jpg"]["large_image_url"]
                          }
                          alt="Dan Abramov"
                        />
                      </VStack>
                      <VStack>
                        <Box
                          as="button"
                          display="flex"
                          mt="2"
                          alignItems="center"
                          onClick={() => {
                            setResults([recdata["entry"]]);
                            handleSelected(recdata["entry"]);
                          }}
                        >
                          {recdata["entry"]["title"]}
                        </Box>
                      </VStack>
                    </HStack>
                  ))}
                </VStack>
              )}
            </VStack>
          </Grid>
        </Box>
      </Container>
    </CookiesProvider>
  );
};
