import React from "react";
import {
  Link,
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Spacer,
  Center,
  Heading,
} from "@chakra-ui/react";

import Logo from "./Logo";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Helmet } from "react-helmet";
export const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Heading>{props.title}</Heading>
      <Spacer />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

export const NavBarComingSoon = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      {/* <Logo w="100px" /> */}
      <Spacer />
      <ColorModeSwitcher />
    </NavBarContainer>
  );
};

const XIcon = () => (
  // <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
  //   <title>Close</title>
  //   <path d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z" />
  // </svg>
  <CloseIcon />
);

const MenuIcon = () => (
  // <svg width="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  //   <title>Menu</title>
  //   <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  // </svg>
  <HamburgerIcon />
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <XIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen }) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "column", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="/" isLast={false}>
          Home
        </MenuItem>
        <MenuItem to="/recommendations" isLast={false}>
          Find a Recommendation
        </MenuItem>
        <MenuItem to="/randomanime" isLast={false}>
          Random Anime
        </MenuItem>
        <MenuItem to="/dailyrecommendation" isLast={false}>
          Daily Recommendation
        </MenuItem>
        <MenuItem to="/signup" isLast>
          <Button
            size="sm"
            rounded="md"
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"],
            }}
          >
            Create Account
          </Button>
        </MenuItem>
        <ColorModeSwitcher />
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  const pathname = window.location.pathname;
  console.log(pathname);
  return (
    <Flex
      as="nav"
      wrap="wrap"
      alignItems={"center"}
      w="100%"
      mb={8}
      p={8}
      borderColor={"#07BCDC"}
      borderBottomWidth={1}
      {...props}
    >
      {children}
    </Flex>
  );
};
