import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  OrderedList,
  Button,
  HStack,
  Image,
  Container,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavBar } from "./components/NavBar";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Home } from "./Home";
import { Recommendations } from "./Recommendations";
import { DailyRecommendation } from "./DailyRecommendation";
import { RandomAnime } from "./RandomAnime";
export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/randomanime" element={<RandomAnime />} />
          <Route
            path="/dailyrecommendation"
            element={<DailyRecommendation />}
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};
