import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  OrderedList,
  Button,
  HStack,
  Image,
  Container,
  Checkbox,
  CheckboxGroup,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavBar } from "./components/NavBar";
import { CookiesProvider, useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
export const RandomAnime = ({}) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [genres, setGenres] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchloading, setSearchLoading] = useState(false);
  const filtered = genres.filter((entry) =>
    Object.values(entry).some(
      (val) =>
        typeof val === "string" &&
        val.toLowerCase().startsWith(searchTerm.toLocaleLowerCase())
    )
  );
  useEffect(() => {
    setLoading(true);

    setLoading(false);
    console.log(searchTerm);
    axios
      .get(`https://api.jikan.moe/v4/genres/anime`)
      .then(function (response) {
        // handle success
        console.log(response);
        setGenres(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);
  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        console.log(searchTerm);
        setResults(filtered);
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);
  const handleSearch = () => {
    console.log("runningSearch");
    // Simulate form submission delay
    setTimeout(() => {
      axios
        .get(
          `https://api.jikan.moe/v4/anime?genres=${Object.values(
            selectedGenres.map((obj) => obj.mal_id)
          ).join(",")}`
        )
        .then(function (response) {
          // handle success
          setSearchResults(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {});
    }, 2000);
  };
  const location = useLocation();
  return (
    <CookiesProvider>
      <NavBar title={"Random Anime"} />
      <Container>
        <InputGroup>
          <InputLeftAddon>Search for an anime: </InputLeftAddon>
          <Input
            type="text"
            name="username"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {loading && (
          <div style={{ marginTop: 5, fontWeight: "bold" }}>Loading...</div>
        )}
        {results.map((data, key) => (
          <HStack key={key} minW={"50vw"}>
            <VStack>
              <Box
                as="button"
                display="flex"
                mt="2"
                alignItems="center"
                onClick={() => {
                  !selectedGenres.some((obj) => {
                    return obj.mal_id === data.mal_id;
                  }) &&
                    setSelectedGenres([
                      ...selectedGenres,
                      { mal_id: data["mal_id"], name: data["name"] },
                    ]);
                }}
              >
                {data["name"]}
              </Box>
            </VStack>
          </HStack>
        ))}
        <HStack spacing={5}>
          {selectedGenres.map((data, key) => (
            <Checkbox
              key={key}
              defaultChecked
              onChange={(e) => {
                const newItems = selectedGenres.filter(
                  (item) => item.mal_id !== data["mal_id"]
                );
                setSelectedGenres(newItems);
                console.log(newItems);
              }}
            >
              {data["name"]}
            </Checkbox>
          ))}
        </HStack>
        <VStack>
          <Button onClick={() => handleSearch()}>Search for Genres</Button>
          {searchloading == false && searchResults.length > 0 && (
            <VStack align={"flex-start"}>
              {searchResults.map((data, key) => (
                <HStack key={key} minW={"50vw"}>
                  <VStack>
                    <Image
                      boxSize="75px"
                      objectFit="cover"
                      src={data["images"]["jpg"]["large_image_url"]}
                    />
                  </VStack>
                  <VStack>
                    <Box
                      as="button"
                      display="flex"
                      mt="2"
                      alignItems="center"
                      onClick={() => {}}
                    >
                      {data?.title_english ?? data["title"] ?? data?.titles[0]}
                    </Box>
                  </VStack>
                </HStack>
              ))}
            </VStack>
          )}
        </VStack>
      </Container>
    </CookiesProvider>
  );
};
